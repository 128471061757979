<template>
    <div>
        <template v-if="updateMode && !listMode">
            <v-btn
                :loading="loading.salesOrder"
                class="ml-3"
                small
                @click.stop="printSalesOrder()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.salesOrder') }}</span>
            </v-btn>
            <v-btn
                :loading="loading.proformaInvocie"
                small
                class="ml-3"
                @click.stop="printProformaInvoice()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') }}</span>
            </v-btn>
        </template>
        <template v-if="updateMode && listMode">
            <v-list-item class="font-sm" @click.stop="printSalesOrder()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.salesOrder"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.salesOrder') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printProformaInvoice()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.proformaInvoice"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.proformaInvoice') }}</span>
            </v-list-item>
        </template>
        <PrintSalesOrderBlob
            :sales-order-id="salesOrderId"
            :key="updateSalesOrderKey"
            @pdf-uploading="salesOrderUploading"
            @pdf-uploaded="salesOrderUploaded"
            @pdf-not-uploaded="salesOrderNotUploaded"
            v-if="hasSalesOrder && version == 1.0"
        />
        <PrintSalesOrderBlobV2
            :sales-order-id="salesOrderId"
            :key="updateSalesOrderKey"
            @pdf-uploading="salesOrderUploading"
            @pdf-uploaded="salesOrderUploaded"
            @pdf-not-uploaded="salesOrderNotUploaded"
            v-if="hasSalesOrder && version > 1.0"
        />
        <PrintProformaInvoiceBlob
            :sales-order-id="salesOrderId"
            :key="updateProformaInvoiceKey"
            @pdf-uploading="proformaInvoiceUploading"
            @pdf-uploaded="proformaInvoiceUploaded"
            @pdf-not-uploaded="proformaInvoiceNotUploaded"
            v-if="hasProformaInvoice && version == 1.0"
        />
        <PrintProformaInvoiceBlobV2
            :sales-order-id="salesOrderId"
            :key="updateProformaInvoiceKey"
            @pdf-uploading="proformaInvoiceUploading"
            @pdf-uploaded="proformaInvoiceUploaded"
            @pdf-not-uploaded="proformaInvoiceNotUploaded"
            v-if="hasProformaInvoice && version > 1.0"
        />
    </div>
</template>

<script>

import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";

const PrintProformaInvoiceBlob = () => import('Views/v1/salesorders/PrintProformaInvoiceBlob');
const PrintProformaInvoiceBlobV2 = () => import('Views/v1/salesorders/PrintProformaInvoiceBlobV2');
const PrintSalesOrderBlob = () => import('Views/v1/salesorders/PrintSalesOrderBlob');
const PrintSalesOrderBlobV2 = () => import('Views/v1/salesorders/PrintSalesOrderBlobV2');

export default {
    name: "PrintSalesOrderButtons",
    props: ['salesOrderId','updateMode','listMode','version'],
    components: {PrintProformaInvoiceBlob, PrintSalesOrderBlob, PrintProformaInvoiceBlobV2, PrintSalesOrderBlobV2},
    data() {
        return {
            currentSalesOrderPdf: null,
            currentProformaInvoicePdf: null,
            hasSalesOrder: false,
            hasProformaInvoice: false,
            loading: {
                salesOrder: false,
                proformaInvoice: false
            },
            updateProformaInvoiceKey: 1,
            updateSalesOrderKey: 2
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/localsalesorders/" + this.salesOrderId, {
                        params: {
                            fields: [
                                'localsalesorders.location AS salesOrders__location',
                                'localsalesorders.pi_location AS salesOrders__pi_location'
                            ]
                        }
                    })
                    .then(response => {
                        const salesOrder = response.data.data[0]
                        if (salesOrder) {
                            this.currentSalesOrderPdf = salesOrder.SalesOrder.location
                            this.currentProformaInvoicePdf = salesOrder.SalesOrder.pi_location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        proformaInvoiceNotUploaded() {
            this.loading.proformaInvoice = false
            this.hasProformaInvoice = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        proformaInvoiceUploaded(url) {
            this.currentProformaInvoicePdf = url
            this.loading.proformaInvoice = false
            this.hasProformaInvoice = false
            this.viewFile(this.currentProformaInvoicePdf)
        },
        proformaInvoiceUploading() {
            this.loading.proformaInvoice = true
        },
        salesOrderNotUploaded() {
            this.loading.salesOrder = false
            this.hasSalesOrder = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        salesOrderUploaded(url) {
            this.currentSalesOrderPdf = url
            this.loading.salesOrder = false
            this.hasSalesOrder = false
            this.viewFile(this.currentSalesOrderPdf)
        },
        salesOrderUploading() {
            this.loading.salesOrder = true
        },
        printSalesOrder() {
            this.loading.salesOrder = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentSalesOrderPdf != null){
                        this.viewFile(this.currentSalesOrderPdf)
                            .then(() => this.loading.salesOrder = false)
                            .catch(() => this.loading.salesOrder = false)
                    } else {
                        this.hasSalesOrder = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.salesOrder = false
                    this.hasSalesOrder = false
                })
        },
        printProformaInvoice() {
            this.loading.proformaInvoice = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentProformaInvoicePdf != null){
                        this.viewFile(this.currentProformaInvoicePdf)
                            .then(() => this.loading.proformaInvoice = false)
                            .catch(() => this.loading.proformaInvoice = false)
                    } else {
                        this.hasProformaInvoice = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.proformaInvoice = false
                    this.hasProformaInvoice = false
                })
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        }
    },
    created() {
        if(this.version == null) this.version = 1.0
    }
}
</script>

<style scoped>

</style>